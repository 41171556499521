import React, { useCallback, useContext } from 'react';
import * as S from './ChildrenOnly.styles';
import FunnelContext from '../../context/FunnelContext';
import useButtonEventPressed from '../../hooks/useButtonEventPressed';

const TubSelector = props => {
  const { tubs, children, activeColor, styles = 'default' } = props;

  const context = useContext(FunnelContext);

  const {
    currentCategory,
    setCurrentProduct,
    setTubIndex,
    currentTubIndex,
    setCurrentTubIndex,
    setCurrentTub,
    extraObjects: extraObjectsContext,
    setExtraObjects
  } = context;

  const applyExtraObjects = useCallback(
    extraObjects => {
      if (extraObjects) {
        extraObjects.map(({ key, value }) => {
          if (!key) return false;
          const newExtraObjects = Object.assign(extraObjectsContext, {
            [key]: value
          });
          return setExtraObjects(newExtraObjects);
        });
      }
    },
    [extraObjectsContext, setExtraObjects]
  );

  useButtonEventPressed('tub-selector-item');

  const handleClick = (tub, index) => {
    setTubIndex(index);
    setCurrentTubIndex(index);
    setCurrentTub(tub);
    setCurrentProduct(
      currentCategory === `subscriptions`
        ? `subscription_${index}`
        : `onetime_${index}`
    );

    // set extraObjects
    applyExtraObjects(tub?.extraObjects);
  };

  return (
    <>
      {tubs.map((tub, index) => {
        const active = currentTubIndex === index;
        return typeof children[index] !== 'undefined' && children[index] ? (
          <S.ChildWrapper
            active={active}
            className={`tub-selector-item ${active ? `active` : ``}`}
            styles={styles}
            activeColor={activeColor}
            onClick={() => handleClick(tub, index)}
            key={index}
          >
            {children[index]}
          </S.ChildWrapper>
        ) : null;
      })}
    </>
  );
};

export default TubSelector;
