import styled from 'styled-components';

export const Container = styled.div`
  all: inherit;
  & .category-selector {
    cursor: pointer;
    transition: all 1s ease 5ms;
    &:hover {
      border: 2px solid #cd0053;
    }
    &.active {
      border: 2px solid #cd0053;
      background-color: #fae5ee;
    }
  }
`;
