import styled from 'styled-components';

export const ChildWrapper = styled.div`
  width: 100%;
  display: flex;
  cursor: pointer;
  position: relative;
  & > div {
    background: transparent;
    transition: background 500ms;
  }
  &.active > div {
    ${({ activeColor = `transparent` }) => `background: ${activeColor};`}
  }

  &:before {
    content: '';
    display: block;
    border: 6px solid transparent;
    position: absolute;
    border-radius: 14px;
    width: calc(100% + 12px);
    height: calc(100% + 12px);
    top: -6px;
    left: -6px;
    transition: border 800ms;
  }

  ${({ styles }) => {
    switch (styles) {
      case 'pdp2023':
        return `
          &.active > div,
          &.active:hover > div {
            border: 2px solid #CD0053;
          }
          &.active:hover:before {
            border: 2px solid transparent;
          }
          &:hover > div,
          &:active > div,
          &.pressed > div {
            border: 2px solid #CD0053;
          }
          &:hover:before ,
          &:active:before,
          &.pressed:before {
            border-color:#FAE5EE;
          }
        `;
      case 'halloween':
        return `
          &.active > div,
          &.active:hover > div {
            border: 2px solid #F06400;
          }
          &.active:hover:before {
            border: 2px solid transparent;
          }
          &:hover > div,
          &:active > div,
          &.pressed > div {
            border: 2px solid #F06400;
          }
          &:hover:before ,
          &:active:before,
          &.pressed:before {
            border-color:#FEF0E6;
          }
        `;
      default:
        return ``;
    }
  }}
`;
