import React, { useState, useEffect, useCallback, useContext } from 'react';
import FunnelContext from '../../context/FunnelContext';
import * as S from './ChildrenOnly.styles';

const ChildrenOnly = props => {
  const { items, children } = props;
  const [init, setInit] = useState(false);

  const { currentCategory, setCurrentCategory } = useContext(FunnelContext);

  const scanElements = useCallback((elementId, callback) => {
    const interval = setInterval(() => {
      const elements = document.getElementsByClassName(elementId);
      if (elements.length > 0) {
        callback(elements);
        clearInterval(interval);
      }
    }, 1);
    setTimeout(() => {
      clearInterval(interval);
    }, 5000);
  }, []);

  const initChildren = useCallback(() => {
    children.forEach((child, index) => {
      const item = items[index] || null;
      scanElements(child.key, elements => {
        Array.from(elements).forEach(elm => {
          elm.setAttribute('data-index', index);
          elm.setAttribute('data-category', item.category);
          elm.setAttribute('data-currentcategory', currentCategory);
          elm.classList.add('category-selector');
          elm.addEventListener('click', evt => {
            const category = evt.currentTarget.dataset.category;
            setCurrentCategory(category);
          });
        });
      });
    });
    scanElements('category-selector', elements => {
      Array.from(elements).forEach(elm => {
        const elmCategory = elm.dataset.category;
        const elmCurrentCategory = elm.dataset.currentcategory;
        if (elmCategory !== elmCurrentCategory) {
          elm.classList.remove('active');
        } else {
          elm.classList.add('active');
        }
      });
    });
  }, [children, scanElements, items, setCurrentCategory, currentCategory]);

  useEffect(() => {
    if (!init) {
      initChildren();
      setInit(true);
    }
  }, [init, setInit, initChildren]);

  return <S.Container>{children}</S.Container>;
};

export default ChildrenOnly;
