import React, {
  useLayoutEffect,
  useState,
  useCallback,
  useContext
} from 'react';
import * as S from './ChildrenOnly.styles';
import FunnelContext from '../../context/FunnelContext';
import useLocalStorage from '../../hooks/useLocalStorage';

const ChildrenOnly = props => {
  const {
    children,
    items,
    watchForElement = false,
    watchForElementId,
    openedIndex = 0,
    initializeOnLoad = false
  } = props;
  const [init, setInit] = useState(false);
  const [elementFound, setElementFound] = useState(!watchForElement);

  const {
    currentTubIndex,
    setCurrentCategory,
    setCurrentProduct,
    extraObjects,
    setExtraObjects
  } = useContext(FunnelContext);
  const [, setCurrentCategoryLocal] = useLocalStorage('currentCategory');

  const scanElements = useCallback((elementId, callback) => {
    const interval = setInterval(() => {
      const elements = document.getElementsByClassName(elementId);
      if (elements.length > 0) {
        callback(elements);
        clearInterval(interval);
      }
    }, 100);
    setTimeout(() => {
      clearInterval(interval);
    }, 5000);
  }, []);

  const scanWatchForElement = useCallback(() => {
    if (!watchForElement) return null;
    scanElements(watchForElementId, () => {
      setElementFound(true);
    });
  }, [scanElements, watchForElement, watchForElementId, setElementFound]);

  const identifyHeight = useCallback(
    (reset = false, resetIndex = 0) => {
      scanElements('accordion-body', elements => {
        Array.from(elements).forEach((elm, index) => {
          const item = items[index];
          const itembodyHeight = item?.bodyHeight || 0;
          if (reset && index === resetIndex) {
            elm.style.height = `unset`;
            elm.setAttribute('data-height', elm.offsetHeight + 1);
            elm.style.height = `${elm.offsetHeight + 1}px`;
          }
          if (!reset && !elm.dataset.height) {
            const bodyHeight =
              elm.offsetHeight > itembodyHeight
                ? elm.offsetHeight
                : itembodyHeight;
            elm.setAttribute('data-height', bodyHeight + 1);
            elm.style.height = `${bodyHeight + 1}px`;
          }
        });
      });
    },
    [scanElements, items]
  );

  const headerToggle = useCallback(
    (selectedIndex = 0) => {
      scanElements('accordion-header', elements => {
        Array.from(elements).forEach((elm, index) => {
          if (index !== selectedIndex) {
            elm.classList.remove('active');
          } else {
            elm.classList.add('active');
          }
        });
      });
    },
    [scanElements]
  );

  const tickToggle = useCallback(
    (selectedIndex = 0) => {
      scanElements('accordion-tick', elements => {
        Array.from(elements).forEach((elm, index) => {
          if (index !== selectedIndex) {
            elm.classList.remove('active');
          } else {
            elm.classList.add('active');
          }
        });
      });
    },
    [scanElements]
  );

  const bodyToggle = useCallback(
    (selectedIndex = 0) => {
      scanElements('accordion-body', elements => {
        Array.from(elements).forEach((elm, index) => {
          if (index !== selectedIndex) {
            elm.style.height = `0px`;
          } else {
            elm.style.height = `${elm.dataset.height}px`;
          }
        });
      });
    },
    [scanElements]
  );

  const triggerItem = useCallback(
    (selectedIndex = 0) => {
      const { changeCategory = false, category = 'subscriptions' } =
        items[selectedIndex] || {};
      if (changeCategory) {
        setCurrentCategory(category);
        setCurrentCategoryLocal(category);
        const productPrefix =
          category === `onetime` ? `onetime` : `subscription`;
        setCurrentProduct(`${productPrefix}_${currentTubIndex}`);
        setExtraObjects(
          Object.assign(extraObjects, {
            currentCategory: category
          })
        );
      }
    },
    [
      items,
      setCurrentCategory,
      setCurrentCategoryLocal,
      currentTubIndex,
      setCurrentProduct,
      extraObjects,
      setExtraObjects
    ]
  );

  const addEventListeners = useCallback(() => {
    scanElements('accordion-header', elements => {
      Array.from(elements).forEach((elm, index) => {
        elm.addEventListener('click', () => {
          headerToggle(index);
          tickToggle(index);
          bodyToggle(index);
          triggerItem(index);
        });
      });
    });
  }, [scanElements, tickToggle, bodyToggle, headerToggle, triggerItem]);

  const addIdentifiers = useCallback(() => {
    scanElements('accordion-header', elements => {
      Array.from(elements).forEach((elm, index) => {
        elm.classList.add(`accordion-header-${index}`);
      });
    });
    scanElements('accordion-body', elements => {
      Array.from(elements).forEach((elm, index) => {
        elm.classList.add(`accordion-body-${index}`);
      });
    });
    scanElements('accordion-tick', elements => {
      Array.from(elements).forEach((elm, index) => {
        elm.classList.add(`accordion-tick-${index}`);
      });
    });
  }, [scanElements]);

  const initialize = useCallback(() => {
    identifyHeight();
    headerToggle(openedIndex);
    tickToggle(openedIndex);
    triggerItem(openedIndex);
    bodyToggle(openedIndex);
    setInit(true);
  }, [
    identifyHeight,
    tickToggle,
    bodyToggle,
    triggerItem,
    headerToggle,
    openedIndex,
    setInit
  ]);

  useLayoutEffect(() => {
    if (!init) {
      scanWatchForElement();
      if (elementFound) {
        addEventListeners();
        addIdentifiers();
        if (!initializeOnLoad) {
          initialize();
        }
        if (initializeOnLoad) {
          window.addEventListener('load', initialize);
        }
      }
    }
    return () => {
      if (initializeOnLoad) {
        window.removeEventListener('load', initialize);
      }
    };
  }, [
    init,
    setInit,
    addIdentifiers,
    scanWatchForElement,
    addEventListeners,
    elementFound,
    initializeOnLoad,
    initialize
  ]);

  return <S.Container>{children}</S.Container>;
};

export default ChildrenOnly;
