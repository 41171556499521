import styled from 'styled-components';

export const Container = styled.div`
  all: inherit;

  & .accordion-tick.active {
    background-color: #001c72;
    position: relative;
    &:before {
      content: '';
      display: block;
      background-color: #fff;
      border-radius: 100%;
      width: 16px;
      height: 16px;
      margin: auto;
    }
  }

  & .accordion-header {
    cursor: pointer;
  }

  & .accordion-body {
    transition: height 500ms ease 0s;
    overflow: hidden;
  }
`;
